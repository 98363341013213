<template>
  <div>
    <v-card
      class="pa-9"
      width="400"
      elevation="8"
      style="margin: auto; margin-top: 10%; border-radius: 20px"
    >
    <v-img
      height="75px"
      width="200px"
      src="../assets/logo1.png"
      alt="Default logo"
    ></v-img>
    <v-card-text>
    <h1 style="color: red">Maintenance Notice</h1>
    <br>
    <p style="text-align:justify">
      We are currently undergoing maintenance. During this time, please process transactions manually. 
      We apologize for any inconvenience and appreciate your understanding. 
    </p>
    <br>
    <p>Thank you!</p>
    </v-card-text>
    </v-card>

    <br />
    <!-- <h1>🌐 New Website Link: [http://sys-com_svr/hrdinquiry/login]</h1> -->
  </div>
</template>

<script>
export default {};
</script>

<style></style>
